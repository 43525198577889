body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif !important;
    color: #ffffff !important;
}
.page-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: #060a24;
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    padding: 20px;
    overflow: hidden;
}

.register-content-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    // align-items: center;
    width: 100%;
    max-width: 1400px;
    // min-height: 90vh;
}

.left-section {
    width: 100%;
    max-width: 550px;
    // min-height: 876px;
    margin: 10px;
    box-sizing: border-box;
    padding: 25px;

    .already-account-alert {
        background-color: #1e2239;
        width: fit-content;
        padding: 10px;
        border-radius: 10px;
        margin-top: 30px;

        p {
            margin: 0;
        }

        a {
            color: #ffffff;
        }
    }

    .finish-text-wrapper {
        
        @media screen and(max-width: 385px) {
            h2 {
            font-size: 40px !important;
            margin-bottom: 0;
            }

            h3 {
                margin-top: 5px;
                font-size: 30px !important;
                font-weight: 400;
            }

            .finish-title {
                font-size: 30px !important;
            }
        }

        h2 {
            font-size: 50px;
            margin-bottom: 0;
        }

        h3 {
            margin-top: 5px;
            font-size: 40px;
            font-weight: 400;
        }

        .trial-message {
            background-color: #1e2239;
            width: fit-content;
            padding: 10px;
            border-radius: 10px;

            p {
                margin: 0;
            }
            
            a {
                color: #fff;
            }
        }
    }

    h1  {
        margin-top: 75px;
        font-weight: 500;
    }

    h2 {
        margin-top: 75px;
    }
    
    p {
        font-weight: 400;
    }

    span {
        color: #d63230;
    }
}

.right-section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 550px;
    margin: 10px;
}



.right-section-forget-password{
    padding-top: 150px;
    width: 100%;
    max-width: 550px;
    margin: 10px;
    min-height: 70vh;
}

.right-section-login {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 550px;
    min-height: 876px;
    margin: 10px;
}

.forget-password-form-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 30vh;
    background-color: #ffffff;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 15px;
}

.registration-form-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 70vh;
    background-color: #ffffff;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 15px;
    
    @media screen and(max-width: 385px) {
            .finish-title {
                h1 {
                    font-size: 30px !important;
                }
            }
        }

    .finish-title {
        width: 100%;
        box-sizing: border-box;
        padding: 15px 25px;
        h1 {
            margin: 0;
            color: #1C224D;
            font-size: 40px;
        }
    }

    .finish-download-text {
        box-sizing: border-box;
        padding: 0 25px;
        color: #060A24EB;

        span {
            font-weight: 600;
        }
    }

    .qrcode-wrapper {
        box-sizing: border-box;
        padding: 0 20px;
    }

    .qrcode-wrapper svg {
        max-width: 300px;
    }

    .finish-body-text {
        width: 100%;
        box-sizing: border-box;
        padding: 0 25px;
        p {
            color: #060A24EB;
            margin: 0;
            font-size: 15px;
        }
    }

    .finish-download-info {
        display: flex;
        align-items: center;
        min-height: 85px;
        box-sizing: border-box;
        padding: 0 25px;

        svg:nth-child(2) {
            margin-left: 10px;
        }
    }
}

.login-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 40vh;
    background-color: #ffffff;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 15px;
    margin-top: 115px;
}

@media screen and(max-width: 1160px) {
    .login-wrapper {
        margin: 0;
    }
}

@media screen and(max-width: 1179px) {
    // .page-wrapper {
    //     padding: 10px;
    // }

    .left-section {
        min-height: 0;
        margin: 0;

        h1 {
            margin: 0;
        }

        .already-account-alert {
            background-color: #1e2239;
            width: fit-content;
            padding: 10px;
            border-radius: 10px;
            margin-top: 30px;

            p {
                margin: 0;
            }

            a {
                color: #ffffff;
            }
        }
    }

    .right-section {
        margin: 0;
        align-items: flex-start;
    }

    .registration-form-wrapper {
        width: 100%;
    }
}

.forgot-password-finish {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 27vh;
    background-color: #ffffff;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 15px;

    .finish-body-text {
        width: 100%;
        box-sizing: border-box;
        padding: 0 25px;
        p {
            color: #060A24EB;
            margin: 0;
            font-size: 15px;
        }
    }

    .finish-title {
        width: 100%;
        box-sizing: border-box;
        padding: 15px 25px;

        h1 {
            margin: 0;
            color: #1C224D;
            font-size: 40px;
        }
    }
}