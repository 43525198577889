* {
    -moz-user-select: none;
    /* firefox */
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE*/
    user-select: none;
    /* Standard syntax */
}


@media (max-width: 799px) {
    .member-form {
        padding-left: 15px !important;
    }

    .member {
        margin-left: 15px !important;
    }
}

@media (min-width: 850px) and (max-width: 950px) {
    #input {
        padding-right: 20px !important;
        padding-left: 20px !important;
    }
}

p {
    margin-bottom: 0;
}

// nav {
//     width: 100%;
//     background-color: #060a24;
//     box-sizing: border-box;
//     padding: 0 25px;

//     .nav-content-wrapper {
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         height: 4vw;
//     }
// }

.page-wrapper-member {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    background-color: #f2f2f2;
    color: black;
    padding: 0px 35px;
    font-family: Nunito-Light;
    min-height: calc(100vh - 4vw);
}

@media (min-width: 1444px) and (max-width: 1449px) {
    .member-wrapper {
        margin-top: 80px !important;
    }
}

@media (min-width: 1451px) {
    .member-wrapper {
        margin-top: -10px !important;
    }
}

.member-wrapper {
    margin-top: 60px;

    .member {
        margin-left: 185px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 350px;
        text-align: center;
        box-shadow: 2px 2px 5px 0px #ccc;
        margin-bottom: 35px;
        position: relative;

        .member-top {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            background-color: #f4f4f4;
            height: 80px;
            padding: 10px;

            svg {
                opacity: 50%;
                color: black;
            }
        }

        hr {
            margin: 0;
            padding: 0;
            opacity: 10%;
        }

        .member-avatar-wrapper {
            position: absolute;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            top: 35px;
            background-color: #fff;
            height: 126px;
            width: 126px;
            border-radius: 50%;
            outline: 1px solid rgba(0, 0, 0, 0.5);
            outline-offset: -4px;

            .member-avatar {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-image: linear-gradient(red, black);
                height: 110px;
                width: 110px;
                border-radius: 50%;

                h2 {
                    color: #fff;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -60%);
                    font-family: Nunito-Regular;
                    font-size: 44px;
                }
            }
        }

        .member-bottom {
            background-color: #fff;
            flex-grow: 1;
            padding-top: 100px;

            h2 {
                color: #000;
                opacity: 80%;
                font-size: 24px;
                font-weight: 600;
            }

            h3 {
                color: #000;
                opacity: 50%;
                font-size: 18px;
                font-weight: 100;
            }
        }
    }
}

.member-form {
    padding-left: 160px;
    padding-bottom: 80px;

    .form-control:focus {
        border-color: #FF0000;
        box-shadow: none;
    }

    .form-control::placeholder {
        font-size: 16px;
        font-weight: 400;
        padding-bottom: 3px;
    }

    .save-button-wrapper {
        position: relative;
        color: #fff;
        height: 50px;
        width: 130px;
        float: right;

        .select-report-create {
            background-color: #d63230;
            color: #fff;
            height: 36px;
            min-width: 110px;
            box-shadow: 0px 4px #a22321;
            border: none;
            border-radius: 12px;
            text-align: center;
            text-decoration: none;
            position: relative;

            p {
                margin-top: -3px;
                font-size: 16px !important;
                font-family: Nunito-Medium;
            }
        }

        .select-report-create:hover {
            margin-top: 5px;
            box-shadow: 0px 2px #a22321;
            transition: box-shadow 0.1s ease-in-out, margin-top 0.1s ease-in-out;
        }
    }

    .save-button-wrapper:hover {
        button {
            margin-top: 3px;
            box-shadow: 0px 4px #a22321;
        }
    }

    .form-group {
        margin-bottom: 25px;

        label {
            color: #000;
            opacity: 80%;
            font-size: 20px;
            font-weight: 600;
        }
    }

}

@media (max-width: 799px) {
    .return-to-teams {
        font-size: 14px;
        font-weight: 400;
        margin-top: 40px;
        margin-bottom: -100px;
        margin-left: 10px;
        width: 55px;
        cursor: pointer;
    }
}

@media (min-width: 800px) and (max-width: 944px) {
    .return-to-teams {
        font-size: 14px;
        font-weight: 400;
        margin-top: 55px;
        margin-bottom: -100px;
        margin-left: 135px;
        width: 45px;
        cursor: pointer;
    }
}

@media (min-width: 945px) and (max-width: 1450px) {
    .return-to-teams {
        font-size: 14px;
        font-weight: 400;
        margin-top: 65px;
        margin-bottom: -100px;
        margin-left: 165px;
        width: 55px;
        cursor: pointer;
    }
}

@media (min-width: 1451px) {
    .return-to-teams {
        z-index: 0 !important;
        font-size: 14px;
        font-weight: 400;
        margin-top: 75px;
        width: 55px !important;
        margin-left: 170px;
        cursor: pointer;
    }
}

@media only screen and(max-width: 992px) {
    form {
        .form-group {
            label {
                font-size: 20px;
            }
        }
    }
}

@media only screen and(max-width: 500px) {
    form {
        .form-group {
            label {
                font-size: 16px;
            }
        }
    }
}


@media only screen and(max-width: 360px) {
    nav {
        font-size: 12px;

        strong {
            margin-left: 5px;
        }

        p {
            margin-left: 5px;
        }
    }
}

@font-face {
    font-family: Nunito-xLight;
    src: url('../../fonts/Nunito-ExtraLight.ttf');
}

@font-face {
    font-family: Nunito-Light;
    src: url('../../fonts/Nunito-Light.ttf');
}

@font-face {
    font-family: Nunito-Regular;
    src: url('../../fonts/Nunito-Regular.ttf');
}

@font-face {
    font-family: Nunito-Medium;
    src: url('../../fonts/Nunito-Medium.ttf');
}

@font-face {
    font-family: Nunito-SemiBold;
    src: url('../../fonts/Nunito-SemiBold.ttf');
}

@font-face {
    font-family: Nunito-Bold;
    src: url('../../fonts/Nunito-Bold.ttf');
}

@font-face {
    font-family: Nunito-ExtraBold;
    src: url('../../fonts/Nunito-ExtraBold.ttf');
}

@font-face {
    font-family: Nunito-Black;
    src: url('../../fonts/Nunito-Black.ttf');
}